<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ labelStartDate }}
      </h4>
      <div class="col-md-12" style="padding: 0">
        <input
          id="start_date"
          class="form-control"
          type="date"
          v-model="row.start_date"
          v-on:change="onInputChange"
        />
      </div>
      <p><br /></p>
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ labelEndDate }}
      </h4>
      <div class="col-md-12" style="padding: 0">
        <input
          id="end_date"
          class="form-control"
          type="date"
          v-model="row.end_date"
          v-on:change="onInputChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDateFromTo",
  props: ["labelStartDate", "labelEndDate", "start_date", "end_date"],
  data() {
    return {
      row: {
        start_date: this.$props.start_date,
        end_date: this.$props.end_date,
      },
    };
  },
  methods: {
    onInputChange() {
      const data = {
        start_date: this.row.start_date,
        end_date: this.row.end_date,
      };
      this.$emit("dateChange", data);
    },
  },
};
</script>
