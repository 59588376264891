<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="p-0 row">
        <Loading v-if="pgLoading"> </Loading>
        <div v-if="!pgLoading" class="col-md-8 col-xs-12">
          <CardUser :client="row.user" v-on:clientChange="handleClientChange">
          </CardUser>

          <FormSingleLang
            :label="$t('view.contract_info')"
            :labelTitle="$t('view.subject')"
            :labelBody="$t('view.details')"
            :hasTitle="true"
            :title="row.subject"
            :hasBody="true"
            :body="row.details"
            v-on:formChange="handleFormChange"
          >
          </FormSingleLang>

          <CardScheduleType
            :scheduling_type="row.invoices_type"
            @schedulingTypeChange="handleInvoicesTypeChange"
          />

          <CardAmount
            :amount="row.amount"
            :currency="row.currency"
            :bus="bus"
            @inputChange="handleAmountChange"
          />

          <CardAttachments
            :labelAttachment="$t('view.contratct_attachemnts')"
            :attachments="row.attachments"
            :attachment_key="row.attachment_key"
            v-on:attachmentChange="handleAttachmentChange"
          >
          </CardAttachments>
        </div>
        <div v-if="!pgLoading" class="col-md-4 col-xs-12">
          <CardCompany
            v-if="auth.role == 'root'"
            :company_id="row.company_id"
            @companyChange="handleCompanyChange"
          />
          <CardDateFromTo
            :labelStartDate="$t('app.start_date')"
            :labelEndDate="$t('app.end_date')"
            :start_date="row.start_date"
            :end_date="row.end_date"
            v-on:dateChange="handleDateChange"
          >
          </CardDateFromTo>
        </div>
      </div>
    </div>

    <Buttons
      v-if="!pgLoading"
      :btnLoading="btnLoading"
      :btnCurrent="btnCurrent"
      v-on:submitClicked="handelSubmit"
      v-on:cancelClicked="handleCancel"
    >
    </Buttons>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { defineComponent } from "vue";
import CardUser from "@/components/CardUser";
import CardDateFromTo from "@/components/CardDateFromTo";
import CardScheduleType from "@/components/CardScheduleType";
import CardAmount from "@/components/CardAmount";
import CardCompany from "@/components/CardCompany";
import Loading from "@/components/Loading";
import Buttons from "@/components/Buttons";
import CardAttachments from "@/components/CardAttachments";
import FormSingleLang from "@/components/FormSingleLang";

import mitt from "mitt";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
const bus = mitt();

export default defineComponent({
  name: "EditOrNew",
  components: {
    CardAmount,
    CardCompany,
    CardScheduleType,
    FormSingleLang,
    CardDateFromTo,
    CardUser,
    Loading,
    Buttons,
    CardAttachments,
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        access_token: "",
      },

      // row
      row: {
        subject: "",
        amount: "",
        details: "",
        user_id: 0,
        company_id: null,
        invoices_type: 0,
        start_date: "",
        end_date: "",
        status: 1,
        user: {},
        attachment_key: 0,
        attachments: [],
      },
      bus,

      msgCurrent: this.$route.params.id ? "Updated" : "Created",
      btnCurrent: this.$route.params.id ? "Update" : "Create",
      methodType: this.$route.params.id ? "PUT" : "POST",
      pgLoading: this.$route.params.id ? true : false,
      pgCurrent: this.$route.params.id ? "Edit" : "Add New",
      btnLoading: false,

      refs: "contracts",
    };
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }
    setCurrentPageTitle(this.$t("nav.contracts"), "bx bx-money");

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.amount = res.data.row.amount;
          this.row.details = res.data.row.details;
          this.row.subject = res.data.row.subject;
          this.row.start_date = res.data.row.start_date;
          this.row.end_date = res.data.row.end_date;
          this.row.user_id = res.data.row.user_id;
          this.row.invoices_type = res.data.row.invoices_type;
          this.row.status = res.data.row.status;
          this.row.company_id = res.data.row.company_id;

          this.row.attachment_key = res.data.row.attachment_key;
          if (res.data.row.attachments) {
            for (let i = 1; i <= this.row.attachment_key; i++) {
              let x = i - 1;
              this.row.attachments[i] = res.data.row.attachments[x]
                ? res.data.row.attachments[x].url
                : "";
            }
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.$route.params.id
          ? "/" + this.refs + "/" + this.$route.params.id
          : "/" + this.refs,
        method: this.methodType,
        data: {
          amount: this.row.amount.replaceAll(",", ""),
          details: this.row.details,
          subject: this.row.subject,
          start_date: this.row.start_date,
          end_date: this.row.end_date,
          user_id: this.row.user_id,
          user: this.row.user,
          invoices_type: this.row.invoices_type,
          status: this.row.status,
          attachments: this.row.attachments,
          company_id: this.row.company_id,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message:
              this.btnCurrent == "Update"
                ? "تم التعديل بنجاح"
                : "تم الإضافة بنجاح",
          });

          this.$router.push({ name: this.refs });
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },
    handleSortChange(event) {
      this.row.sort = event.sort;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },
    handleInvoicesTypeChange(event) {
      this.row.invoices_type = event.scheduling_type;
    },
    handleUserChange(event) {
      this.row.user_id = event.input;
    },
    handleClientChange(event) {
      this.row.user = event;
    },
    handleDateChange(event) {
      this.row.start_date = event.start_date;
      this.row.end_date = event.end_date;
    },
    handleFormChange(event) {
      this.row.subject = event.title;
      this.row.details = event.body;
    },
    handleAmountChange(event) {
      this.row.amount = event.amount;
    },
    handleAttachmentChange(event) {
      this.row.attachments = event.attachments;
      this.row.attachment_key = event.attachment_key;
    },
    handleCompanyChange(event) {
      this.row.company_id = event.id;
      this.bus.$emit("currency_changed", event.currency);
    },
  },
});
</script>

<style scoped>
.nav .nav-item {
  font-size: 15px !important;
}
</style>
