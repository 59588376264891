<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : 'txt-left'"
      >
        {{ labelAttachment }}
      </h4>
      <div class="mt-3">
        <div
          v-for="i in row.file_key"
          :key="i"
          :class="i == 0 ? 'hidden' : ''"
          :id="'frm2_' + i"
        >
          <div class="row col-12">
            <div class="row col-11">
              <div class="col-12 form-group">
                <label
                  class="control-label"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
                >
                  {{ $t("app.upload_attachment") }}
                </label>

                <input
                  class="form-control"
                  type="file"
                  v-on:change="onFileChange($event, i)"
                />

                <p
                  v-if="row.files[i] && $props.attachment_key"
                  style="margin-top: 5px"
                >
                  <a
                    :href="row.files[i]"
                    target="_blank"
                    class="btn btn-success btn-sm"
                    :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
                    style="color: #fff"
                  >
                    {{ $t("app.show_attachment") }}
                  </a>
                </p>
              </div>
            </div>
            <div class="col-1">
              <div v-if="i != 0" class="col-12 form-group">
                <button
                  type="button"
                  class="btn btn-danger btn-rounded btn-sm btn-bold ui-mt30"
                  @click="removeOption(i)"
                >
                  <span class="icon-holder">
                    <i class="mdi mdi-delete"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="col-12">
              <hr />
              <br />
            </div>
          </div>
        </div>

        <div class="col-12 text-center">
          <button
            type="button"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            class="btn btn-primary btn-rounded btn-bold pull-right"
            @click="addMore()"
          >
            <span class="icon-holder">
              <i class="mdi mdi-plus-circle"></i>
              {{ $t("app.add_new") }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAttachments",
  props: ["labelAttachment", "attachments", "attachment_key"],
  data() {
    return {
      row: {
        // Files
        files: this.$props.attachments ? this.$props.attachments : [],
        file_key: this.$props.attachment_key ? this.$props.attachment_key : 0,
        file_path: [],
      },
    };
  },
  mounted() {
    for (let i = 1; i <= this.row.file_key; i++) {
      this.row.file_path[i] = this.row.files[i];
    }
  },
  created() {
    //
  },
  methods: {
    //
    addMore() {
      this.row.file_key++;
    },

    removeOption(i) {
      document.querySelector("#frm2_" + i).remove();
      this.row.file_path[i] = "";
      this.changeData();
    },

    onFileChange(e, i) {
      const file = e.target.files[0];
      this.createBase64File(file, i);
    },

    createBase64File(fileObject, i) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file_path[i] = e.target.result;
        this.changeData();
      };
    },
    changeData() {
      // files
      for (let i = 1; i <= this.row.file_key; i++) {
        this.row.files[i] = this.row.file_path[i];
      }

      let data = {
        attachments: this.row.files,
        attachment_key: this.row.file_key,
      };
      this.$emit("attachmentChange", data);
    },

    // onFileChange() {
    //     const data = {
    //         attachment: this.row.attachment
    //     }
    //     this.$emit('attachmentChange', data)
    // },

    // openFile() {
    // 	this.$refs.myDropify.click();
    // },

    // onAttachmentChange(e){
    //     const fileObject = e.target.files[0];
    //     this.row.attachment = URL.createObjectURL(fileObject);

    //     const reader = new FileReader();
    //     reader.readAsDataURL(fileObject);
    //     reader.onload = e =>{
    //         this.row.attachment = e.target.result;
    //         this.onFileChange();
    //     };
    // },
  },
};
</script>
